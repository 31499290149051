import React  from "react"
import "./assets/style/waves.css"
import "./assets/style/bootstrap.min.css"
import "./style.css"
import "./assets/style/pink.css"
const Navbar = React.lazy(() => import("./components/Navbar"))
const Section = React.lazy(() => import("./components/Section"))
const Services = React.lazy(() => import("./components/Services"))
const Competencies = React.lazy(() => import("./components/Competencies"))
const Descriptions = React.lazy(() => import("./components/Descriptions"))
const Process = React.lazy(() => import("./components/Process"))
const Features = React.lazy(() => import("./components/Features"))
const Started = React.lazy(() => import("./components/Started"))
const Contact = React.lazy(() => import("./components/Contact"))
const SocialMedia = React.lazy(() => import("./components/SocialMedia"))
const FooterLinks = React.lazy(() => import("./components/FooterLinks"))

export const HomeTwo = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Section />
      <Services />
      <Competencies />
      <Descriptions />
      <Process />
      <Features />
      <Started />
      <Contact />
      <SocialMedia />
      <FooterLinks />
    </React.Fragment>
  )
}
