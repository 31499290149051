import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import registerServiceWorker from "./registerServiceWorker"
import './i18n'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {HomeTwo} from "./HomeTwo";

function App() {
  return(
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<div>Betöltés..</div>}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeTwo}/>
          <Route path="*" component={HomeTwo}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

ReactDOM.render(<App/>, document.getElementById("root"))
registerServiceWorker()
